const HotjarApp = () => {
  const { hotjarId } = {hotjarId: 5103920};
  const hotjarVersion = 6;
  const debug = process.env.NODE_ENV === 'development';

  return (
    <>
      <script
        suppressHydrationWarning
        async
        id={'hotjar-init'}
        dangerouslySetInnerHTML={{
          __html: `(function(h){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:'${hotjarId}',hjsv:${hotjarVersion},hjdebug:${debug}};
          })(window);
          `
        }}
      />
      <script
        suppressHydrationWarning
        async
        id={'hotjar-script'}
        src={`https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${hotjarVersion}`}
      />
    </>
  );
}


export default HotjarApp;
