import {captureRemixErrorBoundaryError} from '@sentry/remix'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react'

import styles from './styles.module.css'
import CrispChat from '~/components/CrispChat'
import HotjarApp from '~/components/Hotjar'

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  console.error(error)

  return (
    <html>
    <head>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width,initial-scale=1"/>
      <meta name="msapplication-TileImage" content="/favicon-32x32.png"/>

      <link rel="icon" href="/favicon.ico"/>
      <link rel="icon" href="/favicon-16x16.png" sizes="16x16"/>
      <link rel="icon" href="/favicon-32x32.png" sizes="32x32"/>
      <link rel="icon" href="/favicon-192x192.png" sizes="192x192"/>
      <link rel="icon" href="/favicon-512x512.png" sizes="512x512"/>
      <link rel="apple-touch-icon-precomposed" href="/apple-touch-icon.png"/>

      <link rel="preconnect" href="https://cdn.shopify.com/"/>
      <title>Oops..</title>
      <link
        rel="stylesheet"
        href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
      />
      <HotjarApp/>
      <CrispChat/>
      <Meta/>
      <Links/>
    </head>
    <body>
    <div className={styles.error_container}>
      <div className={styles.error_content}>
        <img
          width={60}
          height={60}
          className={styles.error_image}
          src="/assets/error.png"
          alt="error"
        />
        <h2 className={styles.error_caption}>
          Oops.. Something went wrong
        </h2>
        <p className={styles.error_text}>
          Please, reload the page or contact support.
        </p>
      </div>
    </div>
    <ScrollRestoration/>
    <Scripts/>
    </body>
    </html>
  )
}

export default function App() {
  return (
    <html>
    <head>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width,initial-scale=1"/>
      <link rel="preconnect" href="https://cdn.shopify.com/"/>

      <meta name="msapplication-TileImage" content="/favicon-32x32.png"/>
      <link rel="icon" href="/favicon.ico"/>
      <link rel="icon" href="/favicon-16x16.png" sizes="16x16"/>
      <link rel="icon" href="/favicon-32x32.png" sizes="32x32"/>
      <link rel="icon" href="/favicon-192x192.png" sizes="192x192"/>
      <link rel="icon" href="/favicon-512x512.png" sizes="512x512"/>
      <link rel="apple-touch-icon-precomposed" href="/apple-touch-icon.png"/>

      <link
        rel="stylesheet"
        href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
      />
      <HotjarApp/>
      <CrispChat/>
      <Meta/>
      <Links/>
    </head>
    <body>
    <Outlet/>
    <ScrollRestoration/>
    <Scripts/>
    </body>
    </html>
  )
}
